import * as Collapsible from '@radix-ui/react-collapsible';
import React, { useState } from 'react';

import { useCompanyFeatureFlag, useHasAccessTo } from '@attentive/acore-utils';
import { Box, Heading, IconButton, Icon, IconCircle, styled, keyframes } from '@attentive/picnic';

import { BrandVoiceSettingsDialog } from '../../brandVoice';
import { useIsSelfServeAIProEnabled } from '../../utils/featureFlags';

const UnstyledCollapsibleTrigger = styled(Collapsible.Trigger, {
  background: 'none',
  border: 'none',
  padding: 0,
  margin: 0,
  cursor: 'pointer',
});

const openAnimation = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-collapsible-content-height)' },
});

const closeAnimation = keyframes({
  from: { height: 'var(--radix-collapsible-content-height)' },
  to: { height: 0 },
});

const StyledTriggerIcon = styled(Icon, {
  transition: 'transform 300ms cubic-bezier(0.87, 0, 0.13, 1)',

  ':hover > &': {
    color: '$textHover',
  },

  '[data-state=open] &': {
    transform: 'rotate(0deg)',
  },
  '[data-state=closed] &': {
    transform: 'rotate(180deg)',
  },
});

type CopyAssistantAccordionProps = {
  open: boolean;
  onOpenChange: (isOpen: boolean) => void;
  children: React.ReactNode;
  onRegenerateOptions: () => void;
  isCopyAssistantDisabled?: boolean;
  isRegenerateDisabled?: boolean;
  showProductTitleFormattingInBrandVoiceSettings?: boolean;
};

const CollapsibleHeader = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const CollapsibleContent = styled(Collapsible.Content, {
  overflow: 'hidden',
  '&[data-state="open"]': {
    animation: `${openAnimation} 300ms cubic-bezier(0.87, 0, 0.13, 1)`,
  },
  '&[data-state="closed"]': {
    animation: `${closeAnimation} 300ms cubic-bezier(0.87, 0, 0.13, 1) forwards`,
  },
});

export const CopyAssistantAccordion = ({
  isCopyAssistantDisabled,
  open,
  onOpenChange,
  children,
  onRegenerateOptions,
  isRegenerateDisabled = true,
  showProductTitleFormattingInBrandVoiceSettings,
}: CopyAssistantAccordionProps) => {
  const [openBrandVoiceDialog, setOpenBrandVoiceDialog] = useState(false);
  const isAIBrandVoiceUIEnabled = useCompanyFeatureFlag('ENABLE_AI_BRAND_VOICE_BRAND_KIT_UI');
  const isAIProBrandVoiceDevEnabled = useCompanyFeatureFlag('ENABLE_AI_PRO_BRAND_VOICE_DEV');
  const enableBrandVoiceSettings = isAIProBrandVoiceDevEnabled && isAIBrandVoiceUIEnabled;
  const isSelfServeAIProEnabled = useIsSelfServeAIProEnabled();

  const hasUpdateBrandVoiceSettingsPermission = useHasAccessTo({
    domainName: 'ACCOUNT_PERMISSION_DOMAIN_NAME_BRAND_KIT',
    featureName: 'ACCOUNT_PERMISSION_FEATURE_NAME_BRAND_KIT_MANAGEMENT',
    accessType: 'UPDATE',
    fallback: true,
  });

  return (
    <Box
      css={{
        backgroundColor: '$bgDecorative3Default',
        borderTop: '1px solid $bgDecorative3Accent',
      }}
    >
      <Collapsible.Root open={open} onOpenChange={onOpenChange}>
        <CollapsibleHeader>
          <UnstyledCollapsibleTrigger aria-label="Toggle accordion" css={{ flexGrow: 1 }}>
            <Box
              css={{ display: 'flex', alignItems: 'center', gap: '$space2', padding: '$space4' }}
            >
              <IconCircle iconName="Magic" size="extraSmall" color="magic" />
              <Heading as="h3" variant="sm" color="default">
                Suggested messages
              </Heading>
            </Box>
          </UnstyledCollapsibleTrigger>
          <Box>
            {hasUpdateBrandVoiceSettingsPermission &&
              (isSelfServeAIProEnabled || enableBrandVoiceSettings) && (
                <IconButton
                  description="Open brand voice settings dialog"
                  iconName="Cog"
                  size="small"
                  variant="subdued"
                  onClick={() => setOpenBrandVoiceDialog(true)}
                  disabled={isCopyAssistantDisabled}
                />
              )}
            <IconButton
              description="Regenerate messages"
              iconName="Sync"
              size="small"
              variant="subdued"
              onClick={onRegenerateOptions}
              disabled={isRegenerateDisabled}
            />
            <UnstyledCollapsibleTrigger css={{ padding: '$space4 $space4 $space4 $space2' }}>
              <StyledTriggerIcon size="small" name="ChevronUp" />
            </UnstyledCollapsibleTrigger>
          </Box>
        </CollapsibleHeader>
        <CollapsibleContent>
          <Box css={{ padding: ' 0 $space4 $space4' }}>{children}</Box>
        </CollapsibleContent>
      </Collapsible.Root>
      <BrandVoiceSettingsDialog
        open={openBrandVoiceDialog}
        onOpenChange={(isOpen) => setOpenBrandVoiceDialog(isOpen)}
        onSaveSuccess={onRegenerateOptions}
        showProductTitleFormatting={showProductTitleFormattingInBrandVoiceSettings}
      />
    </Box>
  );
};
