import React, { Suspense } from 'react';

import { useCompanyFeatureFlag, useHasAccessTo } from '@attentive/acore-utils';
import { Box, Heading, LoadingPlaceholder, Text, styled } from '@attentive/picnic';

import { BrandVoicePreview } from '../BrandVoicePreview';
import { BrandVoiceSettingsForm } from '../BrandVoiceSettingsForm';
import { BrandVoiceSettingsFormLoader } from '../BrandVoiceSettingsFormProvider';

const PageContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$space8',
});

const BrandVoiceBrandKitPageHeader = () => (
  <Box>
    <Heading as="h2" variant="lg" css={{ marginBottom: '$space4' }}>
      Brand voice
    </Heading>
    <Text>
      By default, Attentive AI will use your past messages to define your brand voice in
      AI-generated messages. You can use the "Advanced settings" below for additional control over
      your brand voice.
    </Text>
  </Box>
);

const BrandVoiceBrandKitPageFallback = () => (
  <PageContainer>
    <BrandVoiceBrandKitPageHeader />
    <LoadingPlaceholder css={{ height: '250px', width: '100%' }} />
  </PageContainer>
);

const BrandVoiceBrandKitPageComponent = () => {
  const isShutOffSwitchEngaged = useCompanyFeatureFlag('ENABLE_CA_SHUT_OFF_SWITCH');
  const canUpdate = useHasAccessTo({
    domainName: 'ACCOUNT_PERMISSION_DOMAIN_NAME_BRAND_KIT',
    featureName: 'ACCOUNT_PERMISSION_FEATURE_NAME_BRAND_KIT_MANAGEMENT',
    accessType: 'UPDATE',
    fallback: true,
  });

  return (
    <PageContainer>
      <BrandVoiceSettingsFormLoader />
      <BrandVoiceBrandKitPageHeader />
      {!isShutOffSwitchEngaged && <BrandVoicePreview />}
      {canUpdate && <BrandVoiceSettingsForm showProductTitleFormatting />}
    </PageContainer>
  );
};

export const BrandVoiceBrandKitPage = () => (
  <Suspense fallback={<BrandVoiceBrandKitPageFallback />}>
    <BrandVoiceBrandKitPageComponent />
  </Suspense>
);
